<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br /><br />
        <h3>ULTRASSONOGRAFIA MAMÁRIA</h3>
        <br /><br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          {{ elemento }}
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>

    <!-- <p></p>
      <p></p>

      <h4>Mama direita:</h4>

      <p v-if="laudo.mamaDireitaPrevia1">
        Mastectomia total prévia sem evidências de nódulos.
      </p>

      <p v-if="laudo.mamaDireitaAreolo1">
        Pele e complexo aréolo papilar íntegros.
      </p>

      <p v-if="laudo.mamaDireitaFibroglandular1">
        Parênquima mamário com predominância do tecido fibroglandular sobre o
        tecido.
      </p>

      <p v-if="laudo.mamaDireitaAdiposo1">
        Parênquima mamário com predominância do tecido adiposo sobre o tecido fibroglandular.
      </p> -->

    <!-- <p v-if="laudo.mamaDireitaImagens1">
        Ausência de imagens nodulares sólidas ou císticas.
      </p>

      <p v-if="laudo.mamaDireitaSilicone1">
        Prótese de silicone com forma habitual, contornos regulares, sem sinais
        de descontinuidade e contraturas.
      </p>

      <p v-if="laudo.mamaDireitaMorfoestruturais1">
        Alterações morfoestruturais do tecido fibroglandular, secundário a
        procedimento cirúrgico.
      </p> -->

    <!-- <p v-if="laudo.mamaDireitaSemFluxo1">
        Imagens alongadas, anecóicas, sem fluxo ao estudo Doppler, sugestivas de
        ectasias ductais na região retroareolar.
      </p> -->

    <!-- <p v-if="laudo.checkMamaDireita">
        Formação cística, de limites precisos, conteúdo anecóico, paredes finas,
        reforço  acústico, localizado no {{ laudo.checkMamaDireita22 }} (_ h),
        medindo {{ laudo.checkMamaDireita23 }} cm.
      </p> -->

    <!-- <p v-if="laudo.checkMamaDireitaReforcoAnecoico">
        Formações císticas, de limites precisos, conteúdo anecóico, paredes
        finas, reforço acústico, com o maior medindo
        {{ laudo.checkMamaDireitaReforcoAnecoico22 }} cm.
      </p> -->

    <!-- <p v-if="laudo.checkMamaDireitaMicrocistos">
        Microcistos (diâmetros até 3.0mm), agrupados, com septações finas entre
        eles, reforço acústico, localizados em{{
          laudo.checkMamaDireitaMicrocistos23
        }}
        cm.
      </p> -->
    <!--
      <p v-if="laudo.checkMamaDireitaFormacoes">
        Formações nodulares, hipoecóicas, circunscritas, maior eixo paralelo à
        pele, assim distribuídas:{{ laudo.checkMamaDireitaFormacoes32 }}
        cm.
      </p>

      <p v-if="laudo.checkMamaDireitaContornoIrregular">
        Formação nodular, hipoecóica, contornos irregulares, maior eixo
        perpendicular à pele,</p><p v-if="laudo.checkMamaDireitaContornoIrregular"> projetando sombra acústica, localizada em{{
          laudo.checkMamaDireitaContornoIrregular1
        }}
        (__h), medindo {{ laudo.checkMamaDireitaContornoIrregular2 }} x
        {{ laudo.checkMamaDireitaContornoIrregular3 }} cm, distando
        {{ laudo.checkMamaDireitaContornoIrregular4 }} cm
        <p v-if="laudo.checkMamaDireitaContornoIrregular">
        da aréola e
        {{ laudo.checkMamaDireitaContornoIrregular5 }} cm da pele.
      </p> -->

    <!-- <p v-if="laudo.comentariosAdcionais1">
        Comentários Adcionais: {{ laudo.comentariosAdcionais1 }}
      </p>
      <p></p> -->
    <!-- <h4>Mama Esquerda:</h4>

      <p v-if="laudo.mamaEsquerdaPrevia1">
        Mastectomia total prévia sem evidências de nódulos.
      </p>

      <p v-if="laudo.mamaEsquerdaAreolo1">
        Pele e complexo aréolo papilar íntegros.
      </p>

      <p v-if="laudo.mamaEsquerdaFibroglandular1">
        Parênquima mamário com predominância do tecido fibroglandular sobre o
        tecido.
      </p>

      <p v-if="laudo.mamaEsquerdaAdiposo1">
        Parênquima mamário com predominância do tecido adiposo sobre o tecido
        fibroglandular.</p>


    <folha>
      <p v-if="laudo.mamaEsquerdaImagens1">
        Ausência de imagens nodulares sólidas ou císticas.
      </p>

      <p v-if="laudo.mamaEsquerdaSilicone1">
        Prótese de silicone com forma habitual, contornos regulares, sem sinais
        de descontinuidade e contraturas.
      </p>

      <p v-if="laudo.mamaEsquerdaMorfoestruturais1">
        Alterações morfoestruturais do tecido fibroglandular, secundário a
        procedimento cirúrgico.
      </p>

      <p v-if="laudo.mamaEsquerdaSemFluxo1">
        Imagens alongadas, anecóicas, sem fluxo ao estudo Doppler, sugestivas de ectasias ductais na região retroareolar.
      </p>

      <p v-if="laudo.checkMamaEsquerda">
        Formação cística, de limites precisos, conteúdo anecóico, paredes finas,
        reforço acústico, localizado no {{ laudo.checkMamaEsquerda22 }} (_ h),
        medindo {{ laudo.checkMamaEsquerda23 }} cm.
      </p>

      <p v-if="laudo.checkMamaEsquerdaReforcoAnecoico">
        Formações císticas, de limites precisos, conteúdo anecóico, paredes
        finas, reforço  acústico, com o maior medindo
        {{ laudo.checkMamaEsquerdaReforcoAnecoico22 }} cm.
      </p>

      <p v-if="laudo.checkMamaEsquerdaMicrocistos">
        Microcistos (diâmetros até 3.0mm), agrupados, com septações finas entre
        eles, reforço acústico, localizados em{{
          laudo.checkMamaEsquerdaMicrocistos23
        }}
        cm.
      </p>

      <p v-if="laudo.checkMamaEsquerdaFormacoes">
        Formações nodulares, hipoecóicas, circunscritas, maior eixo paralelo à
        pele, assim distribuídas:{{ laudo.checkMamaEsquerdaFormacoes32 }}
        cm.
      </p>

      <p v-if="laudo.checkMamaEsquerdaContornoIrregular">
        Formação nodular, hipoecóica, contornos irregulares, maior eixo
        perpendicular à pele,
        projetando sombra acústica, localizada em{{
          laudo.checkMamaEsquerdaContornoIrregular1
        }}
        (__h), medindo {{ laudo.checkMamaEsquerdaContornoIrregular2 }} x
        {{ laudo.checkMamaEsquerdaContornoIrregular3 }} cm, distando
        {{ laudo.checkMamaEsquerdaContornoIrregular4 }} cm da
        aréola e
        {{ laudo.checkMamaEsquerdaContornoIrregular5 }} cm da pele.
      </p>

      <p v-if="laudo.comentariosAdcionais2">
        Comentários Adcionais: {{ laudo.comentariosAdcionais2 }}
      </p> -->
    <!-- <p></p>
      <h4>IMPRESSÃO DIAGNOSTICA:</h4>
      <p></p>

      <p v-if="laudo.ultrassonografiaMamariaPadrao">
        Ultrassonografia mamária dentro dos padrões da normalidade.
      </p>

      <p v-if="laudo.proteseSiliconeAspecto">
        Prótese de silicone de aspecto ecográfico normal.
      </p>
      <p></p> -->

    <!-- <h4>Mama direita:</h4>

      <p v-if="laudo.cistoMamario10">Cisto mamário.</p>

      <p v-if="laudo.cistoMamario11">Cistos mamários.</p>

      <p v-if="laudo.microcistosAgrupados1">Microcistos agrupados.</p>

      <p v-if="laudo.ectasiaDuctal1">Ectasia ductal.</p>

      <p v-if="laudo.linfonodoIntramamario2">Linfonodo intramamário.</p>

      <p v-if="laudo.noduloSolidoMamario2">Nódulo sólido mamário.</p>
      <p v-if="laudo.noduloSolidoMamario3">Nódulos sólidos mamários.</p>
      <p></p> -->
    <!-- <h4>Mama Esquerda:</h4>

      <p v-if="laudo.cistoMamario13">Cisto mamário.</p>

      <p v-if="laudo.cistoMamario20">Cistos mamários.</p>

      <p v-if="laudo.microcistosAgrupados100">Microcistos agrupados.</p>

      <p v-if="laudo.ectasiaDuctal20">Ectasia ductal.</p>

      <p v-if="laudo.linfonodoIntramamario40">Linfonodo intramamário.</p>

      <p v-if="laudo.noduloSolidoMamario300">Nódulo sólido mamário.</p>
      <p v-if="laudo.noduloSolidoMamario3012">Nódulos sólidos mamários.</p> -->
    <!--
      <p v-if="laudo.comentariosAdcionais2323">
        Comentários Adcionais: {{ laudo.comentariosAdcionais2323 }}
      </p>

      <p>
        Categoria {{ laudo.categoria }} pelo sistema de padronização BI-RADS
        ultrassonográfico.
      </p>

      <p v-if="laudo.comentariosAdcionais232122">
        Comentários Adcionais: {{ laudo.comentariosAdcionais232122 }} -->
    <!-- </p>
    </folha> -->
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];
    if (this.laudo.transdutorLinear)
      folha.push(
        `Exame realizado com transdutor linear de alta resolução multifrequencial ( ${this.laudo.transdutorLinear} a ${this.laudo.transdutorLinear2} MHz). `
      );
    if (this.laudo.indicacaoExame)
      folha.push(`Indicação do exame ${this.laudo.indicacaoExame}. `);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.inicio = [...folha];
    folha = [];

    if (this.laudo.mamaDireitaPrevia0)
      folha.push(`Mastectomia total prévia sem evidências de nódulos.`);

    if (this.laudo.peleSubcutaneoEsq)
      folha.push(`Pele e subcutâneo ${
        this.laudo.peleSubcutaneoEsq != 'outros'
          ? this.laudo.peleSubcutaneoEsq
          : ''
      }${
        [
          'presença de edema ',
          'presença de retração ',
          'presença de cisto ',
          'outros',
        ].includes(this.laudo.peleSubcutaneoEsq)
          ? ' medindo ' + this.laudo.peleSubcutaneo2Esq + ' cm'
          : ''
      }.

      `);
    if (this.laudo.areolaPapilaEsq)
      folha.push(
        `Aréola e papila  ${
          this.laudo.areolaPapilaEsq != 'outros'
            ? this.laudo.areolaPapilaEsq
            : ''
        }${
          [
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ].includes(this.laudo.areolaPapilaEsq)
            ? ' medindo' + this.laudo.areolaPapila2Esq
            : ''
        }. `
      );
    if (this.laudo.composicaoMamariaEsq)
      folha.push(`Composição mamária  ${this.laudo.composicaoMamariaEsq}. `);
    if (this.laudo.mamaEsqPrevia1)
      folha.push(
        `Ausência de imagens nodulares de etiologia sólida ou cística.`
      );
    if (this.laudo.presencaEsq)
      folha.push(
        `Presença de ${this.numText[this.laudo.presencaEsq]} ${
          this.laudo.presencaEsq > 1
            ? ' nódulos, assim caracterizados:'
            : ' nódulo, assim caracterizado:'
        }`
      );
    if (!!this.laudo.listaPresencasEsq) {
      if (this.laudo.listaPresencasEsq.length > 0)
        for (var i = 0; i < this.laudo.listaPresencasEsq.length; i++) {
          folha.push(` Nódulo ${i + 1} - ${
            this.laudo.listaPresencasEsq[i].presenca.opc1
              ? this.laudo.listaPresencasEsq[i].presenca.opc1
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc2
              ? this.laudo.listaPresencasEsq[i].presenca.opc2
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc3
              ? this.laudo.listaPresencasEsq[i].presenca.opc3
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc4
              ? this.laudo.listaPresencasEsq[i].presenca.opc4
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc5
              ? this.laudo.listaPresencasEsq[i].presenca.opc5
              : ''
          } medindo ${
            this.laudo.listaPresencasEsq[i].presenca.medida1Esq
              ? this.laudo.listaPresencasEsq[i].presenca.medida1Esq
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.medida2Esq
              ? ' x ' + this.laudo.listaPresencasEsq[i].presenca.medida2Esq
              : ''
          } ${
            this.laudo.listaPresencasEsq[i].presenca.medida3Esq
              ? ' x ' +
                this.laudo.listaPresencasEsq[i].presenca.medida3Esq +
                ' cm, '
              : ' cm, '
          }  ${
            this.laudo.listaPresencasEsq[i].presenca.medidaCmPeleEsq
              ? ' distando ' +
                this.laudo.listaPresencasEsq[i].presenca.medidaCmPeleEsq +
                ' cm da pele e '
              : ''
          }  ${
            this.laudo.listaPresencasEsq[i].presenca.medidaCmAreolaEsq
              ? this.laudo.listaPresencasEsq[i].presenca.medidaCmAreolaEsq +
                ' cm da aréola,'
              : ''
          }   ${
            this.laudo.listaPresencasEsq[i].presenca.locPeleAreoEsq
              ? ' localizado ' +
                this.laudo.listaPresencasEsq[i].presenca.locPeleAreoEsq
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq
              ? ' (' +
                this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq +
                (this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq > 1
                  ? ' horas)'
                  : ' hora)')
              : ''
          }.
`);
        }
    }
    if (this.laudo.medida1Esq)
      folha.push(`medindo  ${this.laudo.medida1Esq} x ${
        this.laudo.medida2Esq
      } x ${this.laudo.medida3Esq} cm, distando ${this.laudo.medidaCmPeleEsq}
      cm da pele e ${this.laudo.medidaCmAreolaEsq}  cm da aréola, localizada ${
        this.laudo.locPeleAreoEsq
      } ${this.laudo.horaAreolaEsq > 1 ? 'as ' : 'a'}
      ${this.laudo.horaAreolaEsq} ${
        this.laudo.horaAreolaEsq > 1 ? ' horas ' : ' hora '
      }`);
    if (this.laudo.presImgcmEsq)
      folha.push(
        `Presença de imagem anecóica medindo ${
          this.laudo.presImgcmEsq
        } cm, localizada ${this.laudo.locPeleAreoEsq1}, ${
          this.laudo.presImgOpEsq
        }${
          this.laudo.presImgOpEsq == 'com área sólida medindo '
            ? this.laudo.presImgOpEsq + ' cm.'
            : '.'
        }`
      );
    if (this.laudo.CongCistoCmEsq)
      folha.push(
        `Conglomerado de cistos medindo ${this.laudo.CongCistoCmEsq} cm, localizado  ${this.laudo.CongCistoOpcEsq}.`
      );
    if (this.laudo.mutlImgcmEsq)
      folha.push(
        `Imagens anecóicas, com a maior medindo  ${this.laudo.mutlImgcmEsq} cm, localizada  ${this.laudo.mutlImgOpcEsq}.`
      );

    if (this.laudo.temProteseEsq) {
      folha.push(`Prótese direita`);
      if (this.laudo.texturaEsq)
        folha.push(`Textura  ${this.laudo.texturaEsq}.`);
      if (this.laudo.texturaEsq)
        folha.push(
          `Integridade da superfície  ${this.laudo.integridadeDaSuperEsq}.`
        );
      if (this.laudo.massaInternaEsq)
        folha.push(
          `Massa interna ${this.laudo.massaInternaEsq}${
            this.laudo.massaInternaEsq == 'sim, medindo'
              ? ' ' + this.laudo.massaInternaCmEsq
              : ''
          }.`
        );
      if (this.laudo.texturaEsq)
        folha.push(`Rotação ${this.laudo.rotacaoEsq}.`);
      if (this.laudo.comentariosAdicionaisEsq)
        folha.push(`${this.laudo.comentariosAdicionaisEsq}.`);
    }
    if (this.laudo.axila1) folha.push(`Axila direita`);
    if (this.laudo.axila1) folha.push(`${this.laudo.axila1}`);
    if (this.laudo.presAusDilatacao) 
    folha.push(`${this.laudo.presAusDilatacao == 'Presença'?
    (this.laudo.presAusDilatacao + ' de dilatação ductal' )
    : this.laudo.presAusDilatacao + ' de dilatação ductal'
  }${this.laudo.presAusDilatacao2?
    ( ' ' + this.laudo.presAusDilatacao2  )
    : ''
    }${this.laudo.presAusDilatacao3?
    ( ' ' + this.laudo.presAusDilatacao3  )
    : ''
    }${this.laudo.presAusDilatacao3 == 'com projeção sólida medindo ' && this.laudo.presAusDilatacao4?
    ( this.laudo.presAusDilatacao4)
    : ''
  }.`);
  if (this.laudo.presencaDeLinfonodoImgs) 
  folha.push(`Presença de  ${this.laudo.presencaDeLinfonodoImgs}
  ${this.laudo.presencaDeLinfonodoImgs > 1? ' imagens hipoecóicas com centro hipoecóico, localizadas ':' imagem hipoecóica com centro hipoecóico, localizada ' }${
    this.laudo.presencaDeLinfonodo
  }${
this.laudo.presencaDeLinfonodo == 'na região retroareolar' && this.laudo.presencaDeLinfonodo?
   ', medindo ' + this.laudo.presencaDeLinfonodo2 + ' cm': ''}.`);
  if (this.laudo.presencaDeLinfonodo) 
  folha.push(`Presença de  ${this.laudo.presencaDeLinfonodo}${
this.laudo.presencaDeLinfonodo == 'na região retroareolar' && this.laudo.presencaDeLinfonodo?
   ', medindo ' + this.laudo.presencaDeLinfonodo2 + ' cm': ''}.`);


    if (folha.length > 0) folha.splice(0, 0, 'Mama direita');
    this.mamaEsquerda = [...folha];
    folha = [];

    if (this.laudo.mamaDireitaPrevia2)
      folha.push(`Mastectomia total prévia sem evidências de nódulos.`);

    if (this.laudo.peleSubcutaneo)
      folha.push(`Pele e subcutâneo ${
        this.laudo.peleSubcutaneo != 'outros' ? this.laudo.peleSubcutaneo : ''
      }${
        [
          'presença de edema ',
          'presença de retração ',
          'presença de cisto ',
          'outros',
        ].includes(this.laudo.peleSubcutaneo)
          ? ' medindo' + this.laudo.peleSubcutaneo2 + ' cm'
          :  ''
      }.

      `);
    if (this.laudo.areolaPapila)
      folha.push(
        `Aréola e papila  ${
          this.laudo.areolaPapila != 'outros' ? this.laudo.areolaPapila : ''
        }${
          [
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ].includes(this.laudo.areolaPapila)
            ? ' medindo ' + this.laudo.areolaPapila2
            : ' cm'
        }. `
      );
    if (this.laudo.composicaoMamaria)
      folha.push(`Composição mamária  ${this.laudo.composicaoMamaria}. `);
    if (this.laudo.mamaDireitaPrevia1)
      folha.push(
        `Ausência de imagens nodulares de etiologia sólida ou cística.`
      );
    if (this.laudo.presenca)
      folha.push(
        `Presença de ${this.numText[this.laudo.presenca]} ${
          this.laudo.presenca > 1
            ? ' nódulos, assim caracterizados:'
            : ' nódulo, assim caracterizado:'
        }`
      );
    if (!!this.laudo.listaPresencas) {
      if (this.laudo.listaPresencas.length > 0)
        for (var i = 0; i < this.laudo.listaPresencas.length; i++) {
          folha.push(` Nódulo ${i + 1} - ${
            this.laudo.listaPresencas[i].presenca.opc1
              ? this.laudo.listaPresencas[i].presenca.opc1
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc2
              ? this.laudo.listaPresencas[i].presenca.opc2
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc3
              ? this.laudo.listaPresencas[i].presenca.opc3
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc4
              ? this.laudo.listaPresencas[i].presenca.opc4
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc5
              ? this.laudo.listaPresencas[i].presenca.opc5
              : ''
          } medindo ${
            this.laudo.listaPresencas[i].presenca.medida1Esq
              ? this.laudo.listaPresencas[i].presenca.medida1Esq
              : ''
          } ${
            this.laudo.listaPresencas[i].presenca.medida2Esq
              ? ' x ' + this.laudo.listaPresencas[i].presenca.medida2Esq
              : ''
          }  ${
            this.laudo.listaPresencas[i].presenca.medida3Esq
              ? ' x ' + this.laudo.listaPresencas[i].presenca.medida3Esq
              : ' cm, '
          }${
            this.laudo.listaPresencas[i].presenca.medidaCmPeleEsq
              ? ' distando ' +
                this.laudo.listaPresencas[i].presenca.medidaCmPeleEsq +
                ' cm da pele e '
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.medidaCmAreolaEsq
              ? this.laudo.listaPresencas[i].presenca.medidaCmAreolaEsq +
                ' cm da aréola, '
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.locPeleAreoEsq
              ? ' localizado ' +
                this.laudo.listaPresencas[i].presenca.locPeleAreoEsq
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.horaAreolaEsq
              ? ' (' +
                this.laudo.listaPresencas[i].presenca.horaAreolaEsq +
                (this.laudo.listaPresencas[i].presenca.horaAreolaEsq > 1
                  ? ' horas)'
                  : ' hora)')
              : ''
          }.
`);
        }
    }
    if (this.laudo.medida1)
      folha.push(`medindo  ${this.laudo.medida1} x ${this.laudo.medida2} x ${
        this.laudo.medida3
      } cm, distando ${this.laudo.medidaCmPele}
      cm da pele e ${this.laudo.medidaCmAreola}  cm da aréola, localizada ${
        this.laudo.locPeleAreo
      }
      ${this.laudo.horaAreola} ${
        this.laudo.horaAreola > 1 ? ' horas ' : ' hora '
      }`);
    if (this.laudo.presImgcm)
      folha.push(
        `Presença de imagem anecóica medindo ${
          this.laudo.presImgcm
        } cm, localizada ${this.laudo.locPeleAreoEsq2}, ${
          this.laudo.presImgOp
        }${
          this.laudo.presImgOp == 'com área sólida medindo '
            ? this.laudo.presImgOp + ' cm.'
            : '.'
        }`
      );
    if (this.laudo.CongCistoCm)
      folha.push(
        `Conglomerado de cistos medindo ${this.laudo.CongCistoCm} cm, localizado  ${this.laudo.CongCistoOpc}.`
      );
    if (this.laudo.mutlImgcm)
      folha.push(
        `Imagens anecóicas, com a maior medindo  ${this.laudo.mutlImgcm} cm, localizada  ${this.laudo.mutlImgOpc}.`
      );

    if (this.laudo.temProtese) {
      folha.push(`Prótese esquerda`);
      if (this.laudo.textura) folha.push(`Textura  ${this.laudo.textura}.`);
      if (this.laudo.textura)
        folha.push(
          `Integridade da superfície  ${this.laudo.integridadeDaSuper}.`
        );
      if (this.laudo.massaInterna)
        folha.push(
          `Massa interna ${this.laudo.massaInterna}${
            this.laudo.massaInterna == 'sim, medindo'
              ? ' ' + this.laudo.massaInternaCm
              : ''
          }.`
        );
      if (this.laudo.textura) folha.push(`Rotação ${this.laudo.rotacao}.`);
      if (this.laudo.comentariosAdicionais)
        folha.push(`${this.laudo.comentariosAdicionais}.`);
    }

    if (this.laudo.comentariosAdcionais1)
      folha.push(`${this.laudo.comentariosAdcionais1}`);
    if (this.laudo.axila) folha.push(`Axila esquerda`);
    if (this.laudo.axila) folha.push(`${this.laudo.axila}`);
    if (this.laudo.presAusDilatacaoB) 
    folha.push(`${this.laudo.presAusDilatacaoB == 'Presença'?
    (this.laudo.presAusDilatacaoB + ' de dilatação ductal' )
    : this.laudo.presAusDilatacaoB + ' de dilatação ductal'
  }${this.laudo.presAusDilatacao2B?
    ( ' ' + this.laudo.presAusDilatacao2B  )
    : ''
    }${this.laudo.presAusDilatacao3B?
    ( ' ' + this.laudo.presAusDilatacao3B  )
    : ''
    }${this.laudo.presAusDilatacao3B == 'com projeção sólida medindo ' && this.laudo.presAusDilatacao4B?
    ( this.laudo.presAusDilatacao4B)
    : ''
  }.`);
  if (this.laudo.presencaDeLinfonodoImgsB) 
  folha.push(`Presença de  ${this.laudo.presencaDeLinfonodoImgsB}
  ${this.laudo.presencaDeLinfonodoImgsB > 1? ' imagens hipoecóicas com centro hipoecóico, localizadas ':' imagem hipoecóica com centro hipoecóico, localizada ' }${
    this.laudo.presencaDeLinfonodoB
  }${
this.laudo.presencaDeLinfonodoB == 'na região retroareolar' && this.laudo.presencaDeLinfonodoB?
   ', medindo ' + this.laudo.presencaDeLinfonodo2B + ' cm': ''}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Mama esquerda');
    this.mamaDireita = [...folha];
    folha = [];

    if (this.laudo.proteseSiliconeAspecto)
      folha.push(` Prótese de silicone de aspecto ecográfico normal.`);

    if (folha.length > 0) folha.splice(0, 0, 'Impressão diagnóstica');
    this.impressao = [...folha];
    folha = [];

    if (this.laudo.cistoMamario10) folha.push(` Cisto mamário.`);
    if (this.laudo.cistoMamario11) folha.push(` Cistos mamários.`);
    if (this.laudo.microcistosAgrupados1) folha.push(` Microcistos agrupados.`);
    if (this.laudo.ectasiaDuctal1) folha.push(` Ectasia ductal.`);
    if (this.laudo.linfonodoIntramamario2)
      folha.push(` Linfonodo intramamário.`);
    if (this.laudo.noduloSolidoMamario2) folha.push(`Nódulos sólidos mamário.`);
    if (this.laudo.noduloSolidoMamario3)
      folha.push(`Nódulos sólidos mamários.`);

    if (folha.length > 0) folha.splice(0, 0, 'Mama direita');
    this.impMamaDireita = [...folha];
    folha = [];

    if (this.laudo.cistoMamario13) folha.push(` Cisto mamário.`);
    if (this.laudo.cistoMamario20) folha.push(` Cistos mamários.`);
    if (this.laudo.microcistosAgrupados100)
      folha.push(` Microcistos agrupados.`);
    if (this.laudo.ectasiaDuctal20) folha.push(` Ectasia ductal.`);
    if (this.laudo.linfonodoIntramamario40)
      folha.push(` Linfonodo intramamário.`);
    if (this.laudo.noduloSolidoMamario300)
      folha.push(` Nódulo sólido mamário.`);
    if (this.laudo.noduloSolidoMamario3012)
      folha.push(`Nódulos sólidos mamários.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.impMamaEsquerda = [...folha];
    folha = [];

    if (this.laudo.impDiag1)
      folha.push(`Ultrassonografia mamária dentro dos padrões da normalidade.`);
    if (this.laudo.impDiag2) folha.push(`Nódulos sólidos bilaterais.`);
    if (this.laudo.impDiag3) folha.push(`Nódulo sólido na mama direita.`);
    if (this.laudo.impDiag5) folha.push(`Nódulo sólido na mama esquerda.`);
    if (this.laudo.impDiag6) folha.push(`Cistos mamários.`);
    if (this.laudo.impDiag7) folha.push(`Cistos na mama direita.`);
    if (this.laudo.impDiag8) folha.push(`Cistos na mama esquerda.`);
    if (this.laudo.impDiag9)
      folha.push(`Prótese de silicone de aspecto ecográfico normal.`);
    if (this.laudo.impDiag10)
      folha.push(`Prótese de silicone à direita de aspecto ecográfico normal.`);
    if (this.laudo.impDiag11)
      folha.push(
        `Prótese de silicone à esquerda de aspecto ecográfico normal.`
      );
    if (this.laudo.impDiag12)
      folha.push(`Sinais de rotura intracapsular à direita.`);
    if (this.laudo.impDiag13)
      folha.push(`Sinais de rotura intracapsular à esquerda.`);
    if (this.laudo.impDiag14)
      folha.push(`Sinais de rotura intracapsular bilateral.`);
    if (this.laudo.impDiag15)
      folha.push(`Linfonodos de aspecto habitual nas axilas.`);

    if (this.laudo.comentarioImpressaoDiagnostica)
      folha.push(` ${this.laudo.comentarioImpressaoDiagnostica}`);
    if (this.laudo.categoria1)
      folha.push(` Categoria ${this.laudo.categoria1} pelo sistema de padronização BI-RADS
        ultrassonográfico.`);
    if (this.laudo.comentariosAdicionaisImpDiag)
      folha.push(` ${this.laudo.comentariosAdicionaisImpDiag}`);
    if (this.laudo.comentariosAdicionaisAnaliseComp)
      folha.push(` ${this.laudo.comentariosAdicionaisAnaliseComp}`);

    if (folha.length > 0) folha.splice(0, 0, 'IMPRESSÃO DIAGNOSTICA');
    this.conclusao = [...folha];
    folha = [];

    const todos = [
      ...this.inicio,
      ...this.mamaEsquerda,
      ...this.mamaDireita,

      ...this.impressao,
      ...this.conclusao,
      ...this.impMamaDireita,
      ...this.impMamaEsquerda,
    ];
    let tamFolha = 28;
    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, tamFolha));
      tamFolha = 26;
    }
  },
  data() {
    return {
      mamaDireita: [],
      numText: ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis'],
      via: '',
      mamaEsquerda: [],
      impMamaDireita: [],
      impMamaEsquerda: [],
      conclusao: [],
      impressao: [],
      tamanhoElemento: [],
      titulos: [
        'Mama direita',
        'Mama esquerda',
        'Axila direita',
        'Axila esquerda',
        'Impressão diagnóstica',
        'Prótese esquerda',
        'Prótese direita',
        'IMPRESSÃO DIAGNOSTICA',

        'Conclusão',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }

  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }

  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
